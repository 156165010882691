<template>
  <component
    :is="layoutComponent"
    :is-short="isShortInput"
    :is-nested="isNested"
  >
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>
    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>
    <template
      v-if="row.prepend"
      v-slot:prepend
    >
      {{ row.prepend }}
    </template>

    <template v-slot:input>
      <div :class="{'cursor-not-allowed': isDisabled }">
        <toggle-button
          :value="value"
          :sync="true"
          :disabled="isDisabled"
          :width="inputWidth"
          :height="inputHeight"
          @change="onChangeEventHandler"
        />
      </div>
    </template>
  </component>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'ToggleInput',

  mixins: [inputs],

  data () {
    return {
      value: false
    }
  },

  computed: {

    inputHeight () {
      // if (this.minimal) return 17
      return 22
    },

    inputWidth () {
      // if (this.minimal) return 35
      return 45
    },

    isDisabled () {
      if (this.row.disabled) {
        return this.row.disabled
      }
      return false
    },

    layoutComponent () {
      // if (this.minimal === 'true') return 'MinimalLayout'
      return 'Layout'
    }
  },

  mounted () {
    let val = this.row.value

    if (val === 'true') {
      this.value = true
    } else if (val === 'false') {
      this.value = false
    } else {
      this.value = Boolean(val)
    }
  },

  methods: {
    onChangeEventHandler (updated) {
      this.value = Boolean(updated.value)
      this.$emit('change', this.value)
    }
  }
}

</script>
